import React from "react";
import Layout from "../components/layout";

import "./about.css";

const About = ({ pageTitle }) => {
  return (
    <Layout pageTitle={pageTitle}>
      <div className="about" style={{ textAlign: "left" }}>
        <h1>Histórico</h1>
        <section>
          <h2>Nossas origens</h2>
          <p>
            Probus Gestão Contábil S/S, tem sua história iniciada no ano de
            1956, na pessoa do técnico em contabilidade Seno Kipper, formado no
            curso técnico do Colégio Rosário de Porto Alegre, em 31/12/1956 -
            registro no CRCRS em 02/05/1958, sob número RS 008314, da
            habilitação junto ao CRCRS. De lá até hoje são 62 anos de contínuos
            trabalhos na área contábil. Por muitos anos o técnico em
            contabilidade Seno Kipper atuou na condição de profissional liberal
            junto a empresas na região do vale dos sinos, sempre apoiado por
            seus colaboradores. Tempos difíceis para um novo empreendedor, que
            esteve sempre voltado a garantir os melhores resultados aos
            clientes, num período em que a região era formada principalmente por
            empreendedores na indústria calçadista. Porém já naqueles tempos
            trabalhávamos com clientes dos mais diversos setores, indústria,
            comércio e serviços.
          </p>
        </section>
        <section>
          <h2>Sedes</h2>

          <p>Tivemos alguns endereços durante esta longa trajetória:</p>

          <ol>
            <li>Na rua José de Alencar, em NH.</li>
            <li>Na Av. Pedro Adams Filho, na galeria Percio Haas, em NH</li>
            <li>Na Rua Lima e Silva, junto a Galeria Central em NH .</li>
            <li>Na Pedro Adams Filho, no edifício Fin hab, em NH</li>
            <li>Av. Nicolau Becker, ed. Rio Reno, em NH .</li>
            <li>Rua 25 de julho, 1037, bairro Rio Branco em NH(atualmente).</li>
          </ol>
        </section>
        <section>
          <h2>Estrutura</h2>
          <p>
            Desde 2010, atuamos no formato de PJ, sociedade de profissionais, já
            na segunda geração familiar. Tendo como diretor o Sr. Olavo Augusto
            Kipper, contador, formado pela Unisinos em 1989. Contamos com apoio
            de 6 colaboradores experientes em suas funções. Em novas
            instalações, perfeitamente adequadas para a atividade, trazendo
            conforto aos clientes e colaboradores. Contamos também com uma ampla
            sala de reunião .
          </p>
        </section>
      </div>
    </Layout>
  );
};

About.defaultProps = {
  pageTitle: "Empresa",
};

export default About;
